<template>
    <el-dialog
        :visible.sync="visible"
        :before-close="popupClose"
        class="custom-dialog dashboard-add"
        :show-close="false"
    >
        <div class="c-modal c-modal--with-tabs" v-loading="loading">
            <div class="c-modal__head">
                <div class="c-modal__title">
                    <h1>{{ $t("NEW_DASHBOARD") }}</h1>
                </div>
                <div class="c-modal__actions">
                    <button class="c-btn c-btn--clean c-btn--gray" @click="popupClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="c-modal__content">
                <div class="o-form">
                    <fieldset>
                        <ul class="o-form__fields o-list-plain">
                            <li class="o-form__item o-form__item--sm o-form__item--stacked">
                                <input
                                    class="o-form__input o-form__input--full"
                                    type="text"
                                    v-model.trim="$v.dashboardName.$model"
                                    :placeholder="$t('ENTER_DASHBOARD_NAME')"
                                    autocomplete="off"
                                    maxlength="32"
                                />
                                <label class="o-form__label">{{ $t("NAME") }}</label>
                                <span
                                    v-if="$v.dashboardName.$dirty && !$v.dashboardName.required"
                                    class="error-msg"
                                    >{{ $t("ENTER_DASHBOARD_NAME") }}</span
                                >
                                <span
                                    v-if="$v.dashboardName.$dirty && !$v.dashboardName.minLength"
                                    class="error-msg"
                                    >{{ $t("MIN_NUMBER_OF_CHARACTERS") }}</span
                                >
                            </li>
                        </ul>
                    </fieldset>
                </div>
            </div>
            <div class="c-modal__footer">
                <button class="c-btn c-btn--outline" @click="popupClose">{{ $t("CANCEL") }}</button>
                <button
                    class="c-btn c-btn--primary"
                    @click="saveDashboardView"
                    :disabled="loading || $v.$anyError"
                >
                    {{ $t("SUBMIT") }}
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import util from "@/helpers/evaluation/util";

export default {
    name: "DashboardViewAdd",
    mixins: [util, validationMixin],
    props: ["visible", "dashboard"],
    data() {
        return {
            loading: false,
            dashboardName: "",
        };
    },
    validations: {
        dashboardName: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(32),
        },
    },
    methods: {
        popupClose() {
            this.$emit("close");
            this.resetFormData();
        },
        saveDashboardView() {
            if (!this.$v.$anyError) {
                this.loading = true;
                dashboardApiClient
                    .post(`/dashboards/${this.dashboard.id}/dashboard-views`, this.dashboardName, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        this.loading = false;
                        const dashboardView = this.getDashboardView(response.data);
                        this.$store.commit("DashboardStore/addDashboardView", dashboardView);
                        this.popupClose();
                        this.$emit("added", dashboardView);
                        this.displayMessage(
                            this.translate("DASHBOARD_APP_NEW_DASHBOARD_SAVED"),
                            "success"
                        );
                    })
                    .catch(() => {
                        this.loading = false;
                        this.displayMessage(this.translate("SAVE_ERROR_MSG"), "error");
                    });
            }
        },
        getDashboardView(responseData) {
            const configuration = JSON.parse(responseData.configuration);
            if (responseData.labelName != null) {
                configuration.label = responseData.labelName;
            }
            return {
                id: responseData.id,
                title: configuration.title,
                configuration,
                position: responseData.dashboardViewPosition,
                dashboard: responseData.dashboard.id,
                editable: responseData.dashboard.editable,
                template: responseData.dashboardViewTemplate,
            };
        },
        resetFormData() {
            this.loading = false;
            this.dashboardName = "";
            this.$v.$reset();
        },
    },
};
</script>
